import { KrScorecycleApi } from '@/api/okr/KrScorecycleApi';
import BaseService from '../base/BaseService';
/**
 * 评分记录
 */
export class KrScorecycleService extends BaseService {
    private api = new KrScorecycleApi();
    
   
    //获取某目标下所有未评分列表
    public getUnScorelist(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.getUnScorelist(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}
