
import {
  modalController,
  popoverController,
  menuController,
  alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import { useRouter } from "vue-router";
// import PeriodSourcePopover from "./PeriodSourcePopover"
import SearchConditionPopover from "@/views/OKR/selectPullDown/SearchConditionPopover.vue";
import { KeyResultService } from "@/services/okr/KeyResultService";
import { KrScorecycleService } from "@/services/okr/KrScorecycleService";
import { KrScoreLogService } from "@/services/okr/KrScoreLogService.ts";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import { debug, log } from "console";
import BaseModel from "@/services/base/model/BaseModel";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import LoginVue from "@/views/demo/Login.vue";
import NoDate from "@/components/NoData.vue";
export default defineComponent({
  name: "TargrtScore",
  components: {
    Header,
    NoDate,
  },
  data() {
    const router = useRouter();
    const btnIconRight = ["iconduihao"];
    const btnSizeRight = ["34px"];
    const service = new KeyResultService();
    const scorecycleService = new KrScorecycleService();
    const krScoreLogService = new KrScoreLogService();
    const params = this.$route.query;
    return {
      router: router,
      service: service,
      scorecycleService: scorecycleService,
      krScoreLogService: krScoreLogService,
      objectiveService: new ObjectiveService(),
      params: params,
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      value: 3,
      isDevelopScore: true,
      scorecycleArr: new Array<any>(1),
      scorecycleObj: Object.create(null),
      resultList: new Array<any>(),
      periodList: new Array<any>(),
      scorecycleParams: Object.create(null),
      score: 0,
      myScore: 0,
      scoreInfo: new Array<any>(),
      saveParams: Object.create(null),
      userId: "",
      Targetdetails: Object.create(null),
      isScoreType: 1, //1是自评分  2主评分
      krScoreLogParams: Object.create(null),
      needSelfScore: true, //是否需要做自评分
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    //页面初始化
    onInit() {
      this.userId = SystemUtils.loginUser.id;
      //this.score = Number(this.params.score);
      this.getTargetdetails(); //识别用户
    },
    //识别用户是否是目标负责人/目标执行者？
    getTargetdetails() {
      let detailsParams = {
        id: this.params.id,
      };
      //如果负责人和上级负责人不同,1是自评分  2主评分
      const isOKRCharger: boolean = this.params.isOKRCharger == "1";
      const isParentOKRCharger: boolean = this.params.isParentOKRCharger == "1";
      this.isScoreType = isParentOKRCharger ? 2 : 1;
      this.objectiveService.objectiveDetails(detailsParams).then((r: any) => {
        this.Targetdetails = r;
        //是根节点时
        if (!r.parentOkR && isOKRCharger){
          this.isScoreType=2;
        }
        // && this.isScoreType == 1
        this.needSelfScore =r.parentOkR != "" ;
        if (
          this.needSelfScore &&
          isOKRCharger == isParentOKRCharger &&
          isParentOKRCharger
        ) {
          this.needSelfScore = false;
        }
        // if (this.Targetdetails.parentOkR) {
        //   if (this.Targetdetails.parentOkR.users)
        //     for (const usersItem of this.Targetdetails.parentOkR.users) {
        //       if (usersItem.userId == this.userId && usersItem.type == 1) {
        //         this.isScoreType = 2;
        //       }
        //     }
        //   if (this.isScoreType != 2)
        //     if (this.Targetdetails.users)
        //       for (const usersItem of this.Targetdetails.users) {
        //         if (usersItem.userId == this.userId && usersItem.type == 1) {
        //           this.isScoreType = 1;
        //         }
        //       }
        // } else {
        //   if (this.Targetdetails.users) this.isScoreType = 0;
        //   for (const usersItem of this.Targetdetails.users) {
        //     if (usersItem.userId == this.userId && usersItem.type == 1) {
        //       this.isScoreType = 2;
        //     }
        //   }
        // }

        this.getperiodList(); //评分周期
      });
    },
    //获取评分周期
    getperiodList() {
      this.scorecycleParams = {
        objectiveId: this.Targetdetails.id,
        type: this.isScoreType,
      };
      this.scorecycleService
        .getUnScorelist(this.scorecycleParams)
        .then((r: any) => {
          this.scorecycleArr = r.data;
          this.getListByOid(); //关键结果列表
        });
    },
    //关键结果列表
    getListByOid() {
      this.service.getListByOid(this.Targetdetails.id + "").then(
        (r: any) => {
          if (r) {
            this.resultList = r;
            this.resultList.forEach((resultItem) => {
              resultItem.myScore = 0;
              resultItem.leaderScore = 0;
              resultItem.myRemark = "";
              resultItem.leaderRemark = "";
            });
            this.scorecycleObj = this.scorecycleArr[0];
            this.krScoreLogParams = {
              oid: this.Targetdetails.id,
              cycleId: this.scorecycleObj.id,
            };
            this.getObjectiveList();
          } else {
            this.resultList.length = -1;
          }
        },
        (e: any) => {}
      );
    },
    //查看自评分
    getObjectiveList() {
      this.krScoreLogService
        .getKrListByOidAndCycleId(this.krScoreLogParams)
        .then((res: any) => {
          this.scoreInfo = res;
          if (res.data.krScores) {
            for (const resultItem of this.resultList) {
              for (const scoreItem of res.data.krScores) {
                if (resultItem.id == scoreItem.krId) {
                  resultItem.myScore = scoreItem.score;
                  resultItem.myRemark = scoreItem.remark;
                }
              }
            }
          }
        })
        .catch(() => {
          for (const resultItem of this.resultList) {
            resultItem.myScore = 0;
            resultItem.myRemark = "";
          }
        });
    },
    //是否展开评分
    developScore(index: any) {
      this.resultList[index].isDevelopScore = !this.resultList[index]
        .isDevelopScore;
    },
    //评分改变
    myScoreChange(index: number) {
      let num = 0;
      this.resultList.forEach((resultTtem) => {
        num += resultTtem.myScore * (resultTtem.weight / 100);
      });
      this.myScore = Math.round(num * 10) / 10;
    },
    leaderScoreChange(index: number) {
      let num = 0;
      this.resultList.forEach((resultTtem) => {
        num += resultTtem.leaderScore * (resultTtem.weight / 100);
      });
      this.score = Math.round(num * 10) / 10;
    },

    //完成
    onRightClick() {
      if (this.scorecycleArr.length == 0 || this.resultList.length == 0) {
        Utils.presentToastWarning("暂无可评分结果");
        return;
      }
      this.saveParams = {
        cycleId: this.scorecycleObj.id,
        oid: this.Targetdetails.id,
        oname: this.Targetdetails.name,
        score: this.score ? this.score : 0,
        type: this.isScoreType,
      };
      let krScoresArr = [];
      for (let index = 0; index < this.resultList.length; index++) {
        const item = this.resultList[index];
        let saveParamsObj: object = {
          type: this.isScoreType,
          cycleId: this.scorecycleObj.id,
          krId: this.resultList[index].id,
          krName: this.resultList[index].name,
          krWeight: this.resultList[index].weight,
          score:
            this.isScoreType == 2
              ? this.resultList[index].leaderScore
                ? this.resultList[index].leaderScore
                : 0
              : this.resultList[index].myScore
              ? this.resultList[index].myScore
              : 0,
          remark:
            this.isScoreType == 2
              ? this.resultList[index].leaderRemark
              : this.resultList[index].myRemark,
          id: new BaseModel().generateIdNoSys(),
        };
        krScoresArr.push(saveParamsObj);
      }
      this.saveParams.krScores = krScoresArr;
      this.saveScores();
    },
    async saveScores() {
      const alert = await alertController.create({
        header: "提交评分",
        mode: "ios",
        message: "确定提交您的评分吗？",
        buttons: [
          {
            text: "取消",
            role: "cancel",
          },
          {
            text: "确定",
            handler: () => {
              this.krScoreLogService
                .getSaveScores(this.saveParams)
                .then((r: any) => {
                  if (r.data) {
                    this.$router.go(-1);
                  } else {
                    Utils.presentToastWarning("提交失败");
                  }
                });
            },
          },
        ],
      });
      await alert.present();
    },
    async openPeriodSelece(ev: any) {
      const popover = await popoverController.create({
        component: SearchConditionPopover,
        cssClass: "myPullDown",
        event: ev,
        mode: "md",
        componentProps: {
          queryNameArr: this.scorecycleArr,
          queryObj: this.scorecycleObj,
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && data.queryObj) {
        this.scorecycleObj = data.queryObj;
      }
    },
  },
});
