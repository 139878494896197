/**
 * okr_kr_score_log 评分记录 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 评分记录
 */
export class KrScorecycleApi extends TaskBaseService {

    constructor() {
        super("okr/scorecycle");
    }
    //获取某目标下所有未评分列表
    public getUnScorelist(params: any): Promise<any> {
        const path = 'unScorelistForObjective';
        return this.request(path, this.rest.METHOD_GET, params);
    }

     
}